interface MIConfig {
	auth: {
		auth0Aud: string;
		auth0ClientId: string;
		auth0Domain: string;
		auth0ForwardParams?: string;
		auth0CookieDomain?: string;
	};
	hosts: {
		web: string;
		api: string;
		viewer: string;
		viewerEmbed: string;
		contact: string;
		pricing: string;
		privacyPolicy: string;
		termsAndConditions: string;
		termsAndConditionsSubscriber: string;
		termsAndConditionsRegister: string;
		iosHost: string;
		msPlacesGuideHost: string;
	};
	appName: string;
	sentryEnabled: string | boolean;
	sentryUrl: string;
	posthogKey: string;
	posthogHost: string;
	userflowToken: string;
	intercomAppId: string;
	outdoorURL: string;
	outdoorKey: string;
	azureTiles: {
		host: string;
		version: string;
		satelliteTilesetId: string;
	};
	version: string;
}

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace NodeJS {
		interface Global {
			BUILD_NUMBER: string;
			MI: MIConfig;
		}
	}
}

globalThis.BUILD_NUMBER = process.env.BUILD_NUMBER || '0';

/**
 * Extracts config values from process in dev and from the environment.js file in production.
 * Note: Vite will only include variables that are prefixed with CLIENT_ in the built application.
 * Please add any updates to `./server/entrypoint.sh`, `./.env.example`, and here.
 */
globalThis.MI =
	globalThis.MI ||
	({
		auth: {
			auth0Aud: process.env.CLIENT_AUTH0_AUD ?? '',
			auth0ClientId: process.env.CLIENT_AUTH0_CLIENT_ID ?? '',
			auth0Domain: process.env.CLIENT_AUTH0_DOMAIN ?? '',
			auth0ForwardParams: process.env.CLIENT_AUTH0_FORWARD_PARAMS,
			auth0CookieDomain: process.env.CLIENT_AUTH0_COOKIE_DOMAIN,
		},
		hosts: {
			web: (process.env.CLIENT_APP_HOST ?? '') + process.env.BASE_PATH,
			api: process.env.CLIENT_GEOMETRY_API_HOST ?? '',
			viewer: process.env.CLIENT_VIEWER_HOST ?? '',
			viewerEmbed: process.env.CLIENT_VIEWER_EMBED_HOST ?? '',
			contact:
				process.env.CLIENT_CONTACT_HOST ??
				'https://www.mappedin.com/contact-us/',
			pricing:
				process.env.CLIENT_PRICING_HOST ?? 'https://www.mappedin.com/pricing',
			privacyPolicy:
				process.env.CLIENT_PRIVACY_POLICY_URL ??
				'https://info.mappedin.com/privacy-policy',
			termsAndConditions:
				process.env.CLIENT_TERMS_AND_CONDITIONS_URL ??
				'https://info.mappedin.com/terms',
			termsAndConditionsSubscriber:
				(process.env.CLIENT_TERMS_AND_CONDITIONS_SUBSCRIBER_HOST =
					'https://info.mappedin.com/terms'),
			termsAndConditionsRegister:
				(process.env.CLIENT_TERMS_AND_CONDITIONS_REGISTER_HOST =
					'https://info.mappedin.com/terms'),
			iosHost:
				process.env.CLIENT_IOS_HOST ??
				'itms-apps://apps.apple.com/ca/app/mappedin-maker/id6449945802',
			msPlacesGuideHost:
				process.env.CLIENT_MS_PLACES_GUIDE_HOST ??
				'https://developer.mappedin.com/docs/enterprise-apps/microsoft-places-configuration/',
		},
		appName: 'Mappedin Maker',
		sentryEnabled: process.env.CLIENT_SENTRY_ENABLED ?? 'false',
		sentryUrl: process.env.CLIENT_SENTRY_URL ?? '',
		posthogKey: process.env.CLIENT_POSTHOG_KEY ?? '',
		userflowToken: process.env.CLIENT_USERFLOW_TOKEN ?? '',
		intercomAppId: process.env.CLIENT_INTERCOM_APP_ID ?? '',
		posthogHost: process.env.CLIENT_POSTHOG_HOST ?? '',
		outdoorURL: process.env.CLIENT_OUTDOOR_URL ?? '',
		outdoorKey: process.env.CLIENT_OUTDOOR_KEY ?? '',
		azureTiles: {
			host: process.env.CLIENT_AZURE_TILES_HOST ?? 'atlas.microsoft.com',
			version: process.env.CLIENT_AZURE_TILES_VERSION ?? '2024-04-01',
			satelliteTilesetId:
				process.env.CLIENT_AZURE_TILES_SATELLITE_TILESET_ID ??
				'microsoft.imagery',
		},
		version: process.env.VERSION ?? '0',
	} satisfies MIConfig);

export default globalThis.MI as MIConfig;

/**
 * Extracts the first subdomain from a URL if possible.
 *
 * 2024-11-13 Kade Robertson
 *
 * Previously this was solved with a regex:
 * `/(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*)/i`
 *
 * However, this was flagged as containing a ReDoS with the following input:
 * `'s\x00'.repeat(38730) + '\n'`
 *
 * To match the previous behaviour, a domain like `x.y.z.com` will only return
 * `x` as the subdomain. This also maintains the behaviour of considering the
 * subdomain of something like `site.co.uk` to be `site` instead of the expected
 * null (IMO).
 *
 * Basically, treat this as "extract the first section of the domain before the
 * first dot".
 *
 * This benefits in a few ways:
 * - It's technically faster (pointless since we only run this once, but a
 *   benefit nonetheless): https://jsbm.dev/AP93CVx6ARnyL
 * - It doesn't explode with malicious input (should also be hard given we're
 *   using `window.location`)
 */
export const extractSubdomain = (url: URL | Location): string | null => {
	const parts = url.hostname.split('.');
	if (parts.length < 2) {
		return null;
	}
	return parts[0];
};
